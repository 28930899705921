var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-markers"},[_c('h1',[_vm._v("Маркировка отгружаемых позиций цветом")]),_c('div',{staticClass:"bg-markers__content"},[_c('form',{attrs:{"action":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.marker.material),expression:"marker.material"}],attrs:{"type":"text","name":"material","id":"material","placeholder":"Копия из 1С"},domProps:{"value":(_vm.marker.material)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.marker, "material", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.marker.color),expression:"marker.color"}],attrs:{"type":"color","name":"color","id":"color"},domProps:{"value":(_vm.marker.color)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.marker, "color", $event.target.value)}}}),_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.setMarker.apply(null, arguments)}}},[_vm._v("Добавить маркировку")])])]),_c('div',{staticClass:"bg-markers__example"},[_c('h3',[_vm._v("ОБРАЗЕЦ:")]),_c('table',[_c('TableHeader'),_c('tbody',_vm._l((_vm.shipment),function(shop,i){return _c('tr',{key:i},[_c('td',{style:({
              background: shop.name.includes('РЕКЛАМАЦИИ')
                ? shop.shopBG
                : '#fff',
            })},[_vm._v(" "+_vm._s(i + 1)+" ")]),_c('td',{staticClass:"car",style:({
              background: shop.name.includes('РЕКЛАМАЦИИ')
                ? shop.shopBG
                : shop.carBG,
              color: shop.textColor,
              'font-weight': 800,
            })},[_vm._v(" "+_vm._s(shop.car ? shop.car : "Не указана")+" ")]),_c('td',{style:({ fontWeight: 'bold', background: shop.shopBG })},[_vm._v(" "+_vm._s(shop.name)+" ")]),_c('td',{style:({
              background: shop.name.includes('РЕКЛАМАЦИИ')
                ? shop.shopBG
                : '#fff',
            })},[_c('span',{staticClass:"yellow tablepart"},[_vm._v(" "+_vm._s(shop.thickCatalog ? shop.thickCatalog : null)+" ")])]),_c('td',{style:({
              background: shop.name.includes('РЕКЛАМАЦИИ')
                ? shop.shopBG
                : '#fff',
            })},[_c('span',{staticClass:"yellow tablepart"},[_vm._v(" "+_vm._s(shop.thinCatalog ? shop.thinCatalog.quan + "\n" + shop.thinCatalog.region : null))])]),_c('td',{style:({
              background: shop.name.includes('РЕКЛАМАЦИИ')
                ? shop.shopBG
                : '#fff',
            })},[_c('span',{staticClass:"yellow tablepart"},[_vm._v(_vm._s(shop.notebook))])]),_c('td',{style:({
              background: shop.name.includes('РЕКЛАМАЦИИ')
                ? shop.shopBG
                : '#fff',
            })},[_c('span',{staticClass:"yellow tablepart"},[_vm._v(_vm._s(shop.cup))])]),_c('td',{style:({
              background: shop.name.includes('РЕКЛАМАЦИИ')
                ? shop.shopBG
                : '#fff',
            })},[_c('span',{staticClass:"yellow tablepart"},[_vm._v(_vm._s(shop.pack))])]),_c('td',{style:({
              background: shop.name.includes('РЕКЛАМАЦИИ')
                ? shop.shopBG
                : '#fff',
            })},[_c('span',{staticClass:"yellow tablepart"},[_vm._v(_vm._s(shop.folder))])]),_c('td',{style:({
              background: shop.name.includes('РЕКЛАМАЦИИ')
                ? shop.shopBG
                : '#fff',
            })},[_c('span',{staticClass:"yellow tablepart"},[_vm._v(_vm._s(shop.towel))])]),_c('td',{style:({
              background: shop.name.includes('РЕКЛАМАЦИИ')
                ? shop.shopBG
                : '#fff',
            })},[_c('span',{staticClass:"yellow tablepart"},[_vm._v(_vm._s(shop.vine))])]),_c('td',{staticClass:"other-mats",style:({
              background: shop.name.includes('РЕКЛАМАЦИИ')
                ? shop.shopBG
                : '#fff',
            })},[_vm._l((shop.otherMats),function(mat,i){return _c('p',{key:i,style:({
                color: mat.quan ? "#000" : "red",
                fontWeight: mat.quan ? "normal" : "bold",
              })},[_vm._v(" "+_vm._s(mat.quan ? ((mat.name) + " - " + (mat.quan)) : ("" + (mat.name)))+" ")])}),(_vm.marker.material.length > 0)?_c('p',{style:({ background: _vm.marker.color })},[_vm._v(" "+_vm._s(_vm.marker.material)+" - 1 ")]):_vm._e(),_vm._l((shop.samples),function(sample,i){return _c('p',{key:'sample-' + i},[_vm._v(" "+_vm._s(((sample.name) + " - " + (sample.quan)))+" ")])})],2),_c('td',{staticClass:"boxes",style:({
              background: shop.name.includes('РЕКЛАМАЦИИ')
                ? shop.shopBG
                : '#fff',
            })},[_c('p',{staticClass:"user-boxes",staticStyle:{"font-size":"16px","font-weight":"bold"}})]),_c('td',{style:({
              background: shop.name.includes('РЕКЛАМАЦИИ')
                ? shop.shopBG
                : '#fff',
            })},_vm._l((shop.orders),function(order){return _c('div',{key:order.name},[(order.comment && order.picked)?_c('p',{style:({ background: "#53DF00" })},[_vm._v(" "+_vm._s(("Р-" + (order.name.match(/\d+/)[0])))+" ")]):_c('p',[_vm._v(" "+_vm._s(("Р-" + (order.name.match(/\d+/)[0])))+" ")])])}),0)])}),0)],1)]),(_vm.markers)?_c('div',{staticClass:"markers-list"},[_c('h3',[_vm._v("Действующие маркировки")]),_vm._l((_vm.markers),function(marker,m){return _c('div',{key:m,staticClass:"markers-list__item"},[_c('p',{style:({ background: marker.color })},[_vm._v(" "+_vm._s(marker.material)+" - "+_vm._s(marker.color)+" ")]),_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.deleteMarker(marker.id)}}},[_vm._v("Удалить")])])})],2):_vm._e(),(!_vm.markers)?_c('p',[_vm._v("Действующих маркировок нет")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }